<template>
    <a-modal
        :visible="visible"
        :title="`${!form.id ? 'Tambahkan' : 'Perbarui'} - Appreciation Benefits`"
        @ok="ConversionPoint"
        @cancel="handleModalCancel"
        width="70%"
        :destroy-on-close="true"
        :footer="null">
    
        <AForm
            ref="formRef"
            class="myform"
            :model="form"
            :rules="state.rules"
            @keydown="form.onKeydown($event)"
            @finish="handleOk"
            :wrapper-col="{ span: 14 }"
            :scroll-to-first-error="true">

            <!-- name -->
            <a-row class="form-row">
                <a-col :sm="24">
                    <a-form-item
                        label="Nama"
                        label-align="left"
                        :label-col="{ sm: { span: 4 } }"
                        :wrapper-col="{ sm: { span: 24 - 4 } }"
                        required
                        name="name"
                        has-feedback>
                        <AInput
                            placeholder="nama"
                            width="100%"
                            v-model:value="form.name"
                            required/>
                    </a-form-item>
                </a-col>
            </a-row>

            <!-- description -->
            <a-row class="form-row">
                <a-col :sm="24">
                    <a-form-item
                        label="Deskripsi"
                        label-align="left"
                        :label-col="{ sm: { span: 4 } }"
                        :wrapper-col="{ sm: { span: 24 - 4 } }"
                        required
                        name="description"
                        has-feedback>
                        <AInput
                            placeholder="deskripsi"
                            width="100%"
                            v-model:value="form.description"
                            required/>
                    </a-form-item>
                </a-col>
            </a-row>

            <!-- image -->
            <a-row class="form-row">
                <a-col :sm="24">
                    <a-form-item
                        label="Gambar"
                        label-align="left"
                        :label-col="{ sm: { span: 4 } }"
                        :wrapper-col="{ sm: { span: 24 - 4 } }"
                        name="image"
                        has-feedback>
                        <AInput
                            type="file"
                            accept="image/png,image/gif,image/jpeg" 
                            @change="onFileChange"/>
                        <AImage
                            class="my-2"
                            v-if="form.id"
                            :width="100"
                            :src="form.image_url"/>
                    </a-form-item>
                </a-col>
            </a-row>

            <!-- url -->
            <a-row class="form-row">
                <a-col :sm="24">
                    <a-form-item
                        label="Tautan"
                        label-align="left"
                        :label-col="{ sm: { span: 4 } }"
                        :wrapper-col="{ sm: { span: 24 - 4 } }"
                        name="url"
                        has-feedback>
                        <AInput
                            placeholder="http://example.com"
                            width="100%"
                            v-model:value="form.url"/>
                    </a-form-item>
                </a-col>
            </a-row>

            <a-row class="form-row" type="flex" justify="end">
                <a-col>
                    <a-form-item>
                        <a-button
                            type="primary"
                            html-type="submit"
                            :loading="form.busy"
                            :disabled="form.busy">{{
                            !form.id ? 'Simpan' : 'Perbarui'
                        }}</a-button>
                    </a-form-item>
                </a-col>
            </a-row>
        </AForm>

        <div v-if="form.errors.any()">
            <ASpace>
                <AAlert
                    v-for="(item, index) in form.errors.all()"
                    type="error"
                    :key="index"
                    :message="item.message"
                    banner
                    closable
                    @close="form.clear()" />
            </ASpace>
        </div>
    </a-modal>
</template>
<script>
import {
    defineComponent,
    onMounted,
    reactive,
    ref,
} from 'vue'
import Form from 'vform'
import { Modal, message } from 'ant-design-vue'

export default defineComponent({
    components: {},
    props: {
        visible: [Boolean],
        item: {
            type: Object,
            default: () => ({
                id: null,
            }),
        },
    },
    emits: ['handleOkConversionPoint', 'update:visible'],
    setup(props, { emit }) {
        const form = reactive(new Form({
            id: props.item.id,
            name: '',
            description: '',
            image: null,
            url: '',
        }))

        const formRef = ref();

        const state = reactive({
            data: [],
            page: 1,
            per_page: 1,
            total: 0,
            loading: false,
            rules: {
                name: [
                    {
                        required: true,
                        message: "Nama tidak boleh kosong!",
                    },
                ],
                description: [
                    {
                        required: true,
                        message: "Deskripsi tidak boleh kosong!",
                    },
                ],
            },
        })

        const handleModalCancel = () => {
            emit('update:visible', false)
            emit('update:item', null)
        }

        const onFileChange = (event) => {
            const files = event.target.files || event.dataTransfer.files
            if (!files.length) return
            form.image = files[0]
        }

        const handleOk = async () => {

            // validation form
            await formRef.value.validate()
                .catch(() => {});

            if (!form.id) {
                form.post('/api/appreciation-benefits')
                    .then(({ data }) => {
                        if (data === undefined) {
                            errorMessage.value = `Kode error 500, No response from server`
                            return
                        }

                        message.success('Berhasil menyimpan')

                        handleModalCancel()
                        emit('success', data)
                    })
            } else {
                form.post(`/api/appreciation-benefits-update/${form.id}`)
                    .then(({ data }) => {
                        if (data === undefined) {
                            errorMessage.value = `Kode error 500, No response from server`
                            return
                        }

                        message.success('Berhasil memperbarui')

                        handleModalCancel()
                        emit('success', data)
                    })
            }

        }

        // handle func vue
        onMounted(() => {
            form.name = props.item.name
            form.description = props.item.description
            form.url = props.item.url
            form.image_url = props.item.image

            if (!form.id) {
                form.reset()
            }
        })

        return {
            formRef,
            handleOk,
            form,
            state,
            handleModalCancel,
            onFileChange,
        }
    },
})
</script>

<style lang="scss" scoped>
@import '@/assets/scss/form.scss';
</style>
